<!-- eslint-disable vue/html-self-closing -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <b-card>
    <div id="form-title">Administrar personalização</div>
    <div>
      <div id="card" style="display: flex; flex-direction: row">
        <div style="display: flex; flex-direction: row; gap: 33px">
          <div class="logo-circle">
            <img :src="logoPreview" alt="" srcset="" />
          </div>
          <div class="banner">
            <img :src="bannerPreview" alt="" srcset="" />
          </div>
        </div>
        <div id="form">
          <validation-observer ref="refFormObserver">
            <b-form
              class="p-2"
              @submit.prevent="true"
              @reset.prevent="resetForm"
            >
              <!-- pre-view logo -->
              <!-- Nome -->
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-group label="Nome" label-for="nome">
                  <b-form-input
                    id="name"
                    v-model="name"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Insira o nome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Descrição da plataforma -->
              <validation-provider
                v-slot="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-group label="Descricao" label-for="description">
                  <b-form-input
                    id="description"
                    v-model="description"
                    name="description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Insira a descrição da platafomra"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Logo -->
              <validation-provider
                v-slot="{ errors }"
                name="logo"
                rules="required"
              >
                <b-form-group label="Logo" label-for="logo">
                  <b-form-file
                    id="logo"
                    placeholder="Selecione uma imagem"
                    drop-placeholder="Arraste seu arquivo para aqui..."
                    multiple
                    browse-text="Escolher arquivo"
                    @change="onLogoInputChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- Banner -->
              <validation-provider
                v-slot="{ errors }"
                name="banner"
                rules="required"
              >
                <b-form-group label="Banner" label-for="banner">
                  <b-form-file
                    id="banner"
                    name="banner"
                    placeholder="Selecione uma imagem"
                    drop-placeholder="Arraste seu arquivo para aqui..."
                    multiple
                    browse-text="Escolher arquivo"
                    @change="onBannerInputChange"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="isBusy"
                  @click="onSubmit"
                >
                  <b-spinner v-if="isBusy" />
                  <template v-else> Enviar </template>
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Pré-visualização
                </b-button> -->
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from 'axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      logo: [],
      banner: [],
      logoPreview: null,
      bannerPreview: null,
      name: '',
      description: '',
      isBusy: false,
    }
  },
  methods: {
    getPersonalizacao(){
      axios.get(`${process.env.VUE_APP_API}/personalizar`)
        .then(response => {
          console.log(response.data)
          this.logoPreview = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.logo}`
          this.bannerPreview = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.banner}`
          this.name = response.data.response.name
          this.description = response.data.response.description
        })
        .catch(error => {
          console.log(error)
        })
    },
    onLogoInputChange(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.logoPreview = URL.createObjectURL(file)
        this.logoFiles = event.target.files
        // eslint-disable-next-line prefer-destructuring
        this.logo = this.logoFiles[0]
      }

      reader.readAsDataURL(file)
    },
    onBannerInputChange(event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.bannerPreview = URL.createObjectURL(file)
        this.bannerFiles = event.target.files
        // eslint-disable-next-line prefer-destructuring
        this.banner = this.bannerFiles[0]
      }

      reader.readAsDataURL(file)
    },
    onSubmit() {
      const payload = new FormData()
      payload.append('logo', this.logo)
      payload.append('name', this.name)
      payload.append('description', this.description)
      payload.append('banner', this.banner)

      axios.post(`${process.env.VUE_APP_API}/personalizar`, payload)
        .then(response => {
          console.log(response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso ao atualizar!',
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
          this.$emit('close')
          this.$emit('onSaved', true)
        })
        .catch(error => {
          console.log(error)
        })
      console.log(payload)
    }
  },
  beforeMount() {
    this.getPersonalizacao()
  }
}
</script>

<style scoped>
#card {
  width: 100%;
  min-height: 364px;
  border-radius: 6px;
  padding: 36px 42px;
}

#form-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
}
.logo-circle {
  width: 110px;
  height: 110px;
  left: 332px;
  top: 305px;
}
.banner {
  width: 354px;
  height: 216px;
  left: 473px;
  top: 305px;

  background: #d9d9d9;
  border-radius: 6px;
}
#token {
  width: 25%;
}
</style>
