var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{attrs:{"id":"form-title"}},[_vm._v("Administrar personalização")]),_c('div',[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"},attrs:{"id":"card"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","gap":"33px"}},[_c('div',{staticClass:"logo-circle"},[_c('img',{attrs:{"src":_vm.logoPreview,"alt":"","srcset":""}})]),_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":_vm.bannerPreview,"alt":"","srcset":""}})])]),_c('div',{attrs:{"id":"form"}},[_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return true.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome","label-for":"nome"}},[_c('b-form-input',{attrs:{"id":"name","name":"name","state":errors.length > 0 ? false : null,"placeholder":"Insira o nome"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descricao","label-for":"description"}},[_c('b-form-input',{attrs:{"id":"description","name":"description","state":errors.length > 0 ? false : null,"placeholder":"Insira a descrição da platafomra"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"logo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Logo","label-for":"logo"}},[_c('b-form-file',{attrs:{"id":"logo","placeholder":"Selecione uma imagem","drop-placeholder":"Arraste seu arquivo para aqui...","multiple":"","browse-text":"Escolher arquivo"},on:{"change":_vm.onLogoInputChange}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"banner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Banner","label-for":"banner"}},[_c('b-form-file',{attrs:{"id":"banner","name":"banner","placeholder":"Selecione uma imagem","drop-placeholder":"Arraste seu arquivo para aqui...","multiple":"","browse-text":"Escolher arquivo"},on:{"change":_vm.onBannerInputChange}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isBusy},on:{"click":_vm.onSubmit}},[(_vm.isBusy)?_c('b-spinner'):[_vm._v(" Enviar ")]],2)],1)],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }